/* Add the following CSS to your About.css file */

/* General styles for the section */
.about {
  max-width: 100%;
  margin: 0 10rem;
  color: rgb(255, 255, 255);
}

/* Styles for the main row container */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Adjust the alignment as needed */
}

/* Styles for the picture column */
.pictureAbout {
  flex-basis: 33.3333%;
  /* Adjust the width here */
  max-width: 33.3333%;
  /* Adjust the max-width here */
  margin-right: 10px;

  height: 550px;
  overflow: hidden;
}

/* Styles for the col2 column */
.col2 {
  flex-basis: calc(66.6666% - 10px);
  /* Adjust the width here */
  max-width: calc(66.6666% - 10px);
  /* Adjust the max-width here */
}

/* Additional styling for the content inside the columns (optional) */
.pictureAbout>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; 
  border-radius: 10px;
}

.titleSubtext {
  font-style: italic;
}

/* Styles for the nested table */
.row .col2-1 {
  flex-basis: calc(50% - 10px);
  /* Adjust the width here */
  max-width: calc(50% - 10px);
  /* Adjust the max-width here */
  margin-right: 10px;
}

.first {
  list-style: none;
}

/* CSS styles for list items with icons */
.first li.IconLi {
  display: flex;
  align-items: center;
  /* Align items vertically in a line */
  margin-bottom: 10px;
  /* Add margin between the list items */

}

.info-block div.IconLi {
  display: flex;
  align-items: center;
  /* Align items vertically in a line */
  margin-bottom: 5px;
  /* Add margin between the list items */

}

.section-title>h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  text-align: left;
  color: #ffffff;
  width: 100%;
}

.section-title>h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: rgb(72, 194, 48);
  bottom: 0;
  left: 0;
}

.title {
  margin-block-start: 0;
}

/* Styles for the two-column layout */
.education-experience {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding-bottom: 20px;
  /* Modify as needed depending on the number of items */

}

.smallText {
 font-size: 12px;
 margin-bottom: 0px;
 margin-top: 2px;
}


.columnExperience {
  margin-left: 20px;
  width: 100%;
  /* Add margin between the columns */
}

.columEducation {
  width: 100%;
}

/* Add the same underline effect to h3 titles */
.columnExperience h3,
.columEducation h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  color: #ffffff;
}

/* Apply the same underline effect to h3 titles as you have for .section-title > h2 */
.columnExperience h3::after,
.columEducation h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  background: rgb(72, 194, 48);
  bottom: 0;
  left: 0;
}

/* add a timeline */
.educationDiv {
  flex-direction: column;
  padding-left: 5px;
  border-left: 2px solid rgba(143, 139, 139, 0.822);
  position: relative;
}




/* Styles for the nested table */
.educationDiv>ul {
  position: relative;
  padding-left: 20px;
  /* Adjust the distance between the circle and the content */
  list-style: none;
  margin: 0;
}

/* Add a circle for each educationUL */
.educationDiv>ul.educationUL::before {
  content: "";
  position: absolute;
  top: 0;
  left: -14px;
  /* Adjust the horizontal position of the circle */
  width: 12px;
  /* Adjust the width of the circle */
  height: 12px;
  /* Adjust the height of the circle */
  border: 2px solid rgb(72, 194, 48);
  /* Add a 2px border with the same color as the line */
  border-radius: 50%;
  background-color: rgb(72, 194, 48);
  /* Set the fill of the circle to transparent */
}



.educationDiv h4 {
  margin: 0;
}

.li>.TitleDegree {
  padding: 0;
}

.educationDiv:not(:last-child) {

  padding-bottom: 30px;
}

.educationDiv .educationUL li:not(:last-child) {
  margin-bottom: 5px;
 
  /* Adjust the margin as needed */
}

.educationUL>li:not(.liYear, .TitleDegree, .liCompany) {
  padding-top: 10px;
}

.educationUL>.TitleDegree {
  padding-bottom: 10px;
  color: rgba(72, 194, 48, 0.89);
}

.educationUL>.liCompany {
  padding-bottom: 10px;
  text-decoration: underline;
}
/* .educationUL> .liPlace {
  padding-bottom: 10px;
} */




/* Styles for the year in the education section */
.liYear {
  display: inline-flex;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.08); */
  /* Set the background color to the shade of blue */
  /* Set the text color to white */

  padding: 0;
  /* Add padding to create space inside the rectangle */
  border-radius: 2px;
  /* Add border-radius to create rounded corners */
}

.Icon {
  color: rgb(72, 194, 48);

  margin-right: 10px;
}

.IconCalendar {
  margin-right: 5px;

  margin-left: 0px;
  stroke-width: 1px;
}

.liPlace {
  font-style: italic;
}

.info-block {
  margin-bottom: 0.5rem;
}

.info-block h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  color: rgb(255, 255, 255);
}

.info-block h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 2px;
  background: rgb(72, 194, 48);
  bottom: 0;
  left: 0;
}

.info-block ul {
  list-style: none;
  padding: 0;
}

.info-block ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-block ul li .Icon {
  margin-right: 1rem;
  color: rgb(72, 194, 48);
}


.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  /* Adjust this to control the gap between the two divs */
}

.Skills {
  display: grid;
  grid-template-columns: auto auto auto;
  /* Modify as needed depending on the number of items */
  gap: 5px;
  /* Modify as needed to adjust the gap between items */
}

.Languages {
  display: grid;
  grid-template-columns: auto;
  /* Modify as needed depending on the number of items */
  gap: 5px;
  /* Modify as needed to adjust the gap between items */
}

.Interests{
  display: grid;
  grid-template-columns: auto;
  /* Modify as needed depending on the number of items */
  gap: 5px;
  /* Modify as needed to adjust the gap between items */
}




.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}


@media (max-width: 1200px) {
  .additional-info {
    flex-direction: column;
    /* Stack columns on smaller screens */
  }

  .Skills {
    grid-template-columns: auto auto;
    /* Modify as needed depending on the number of items */
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    /* Stack columns on smaller screens */
  }
}

/* Media query for smaller screens */
@media (max-width: 980px) {

  .Skills {
    grid-template-columns: auto;
    /* Modify as needed depending on the number of items */
  }

  .about {
    margin: 2rem;
  }

  .row {
    flex-direction: column;
    /* Stack columns on smaller screens */
  }

  .education-experience {
    display: flex;
    flex-direction: column;
    /* Stack columns on smaller screens */
  }


  .education-experience .columnExperience {
    margin: 0 0 0 0;
    /* Add margin between the columns */
  }

  .pictureAbout,
  .col2,
  .col2-1 {
    flex-basis: 100%;
    /* Change to full width on smaller screens */
    max-width: 100%;
    /* Change to full width on smaller screens */
    margin-right: 0;
    /* Remove right margin on smaller screens */
    
  }

  .pictureAbout {
    order: 1;
    /* Change order to 1 on smaller screens (move it above col2) */
    margin-bottom: 20px;
    /* Add margin between columns on smaller screens */
  }

  

  .col2 {
    order: 2;
    /* Change order to 2 on smaller screens (move it below pictureAbout) */
  }

  .col2-1 {
    margin-bottom: 0;
    /* Remove bottom margin on smaller screens */

  }

  .first {
    margin: 0 0 0 0;
  }

}