/* Projects.css */


.projects {
  /* Add any styles for the section here */
  min-height: 135vh;
  color: white;
  max-width: 100%;
  margin: 0 10rem;

}


.projectHeading>h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: rgb(72, 194, 48);
  bottom: 0;
  left: 0;
}

.projectHeading>h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  width: 100%;
}

.projectFilters {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.projectFilters ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.projectFilters li {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
  transition: color 0.3s, background-color 0.3s;
}

.projectFilters li.activeFilter {
  color: rgb(72, 194, 48);
  background-color: transparent;
  text-decoration: underline;
}

.projectFilters li:hover {
  color: rgb(72, 194, 48);
  background-color: rgba(72, 194, 48, 0.034);
}

.projectContent {
  margin-top: 30px;
  width: 100%;


}

.projectGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

/* project Item*/
.projectItem {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border-radius: 15px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 128, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.349);
  max-width: 400px;


}

.projectItem:hover {
  /* box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgb(0, 128, 0); */
  box-shadow: 0 10px 15px 0 rgb(72, 194, 48);
}

.projectThumb {
  position: relative;
  min-width: 400px;
  height: 200px;
  overflow: hidden;

}

.projectThumb img {
  width: 100%;
  height: 100%;
  transition: transform .2s;
}

.projectThumb:hover img {
  transform: scale(1.1);
}

.projectOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.projectMiddle {
  position: relative;
  flex: 1;
  /* flex property added */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.projectText {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.projectText h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff;
  padding-left: 10px;
}

.projectText .description {
  font-size: 16px;
  color: #ccc;

  padding-left: 10px;
}

.projectText .category {
  display: flex;
  justify-content: center;
  /* or space-between, depending on your desired look */
  flex-wrap: wrap;
  /* This will allow the items to wrap to the next line if there's not enough space */
  margin-bottom: 20px;

}

.description {
  margin-bottom: 10px;
}

.projectText .category .categoryItem {
  padding: 8px;
  ;
  color: #aaa;
  font-size: 14px;
}


/* ProjectComponentDetails.css */

.projectComponentDetailsContainer {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  /* Add flex display for centering content */
  justify-content: flex-end;
  /* Align children to the right */

  /* Added a dark background with opacity for modal effect */
  z-index: 9999;
}


.projectComponentDetailsContent {
  background-color: #000000;
  color: #fff;
  width: 30vw;
  /* Set the width to 1/4 of the screen width */
  height: 100%;
  /* Occupy the full height of the viewport */
  /* Text color */
  transform: translateX(100%);
  /* Initially push it 100% to the right */
  transition: transform 0.4s ease-in-out;
  /* Apply the transition on the transform property */
  overflow-y: scroll;
}

.projectComponentDetailsInfo {
  margin: 2em;
  display: flex;
  flex-direction: column;
}

.projectContentImage {
  max-width: 90%;
  /* Avoiding image stretch */
  height: auto;
  /* Maintain the aspect ratio */
  margin: 10px auto;
  /* Center the image */
  display: block;
  /* Image will take the full width of its parent up to the max-width specified */
  border-radius: 8px;
  /* Optional: rounded corners for the image */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  /* Optional: light shadow for the image */
}


.projectComponentDetailsContent.active {
  transform: translateX(0);
  /* Slide in to occupy its natural position */
}

.projectComponentDetailsContent h2,
.projectComponentDetailsContent h3,
.projectComponentDetailsContent h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.projectComponentDetailsContent p {
  margin: 0;
  font-size: 16px;
}


.projectText .skillsList {
  columns: 2;
  /* Split the list into two columns */
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  /* Remove default bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center the skill cards */
}


.projectText .skillsList li {
  background-color: rgba(72, 194, 48, 0.5);
  /* Light background for the card */
  padding: 3px;
  margin: 2px;
  font-size: small;
  border-radius: 4px;
  /* Rounded corners for the card */
  border: 1px solid rgba(72, 194, 48, 0.1);
  /* Thin border for the card */
  transition: background-color 0.3s ease;
  /* Smooth transition effect for hover state */
}

.projectText .skillsList li:hover {
  background-color: rgba(72, 194, 48, 0.2);
  /* Darker background for hover state */
}

/*  POPUP */

.returnDiv>.closeButton {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  color: #fff;

  /* Changed the color to white to match the overall theme */
}

.returnDiv>.closeText {
  margin-top: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  /* Changed the color to white to match the overall theme */
}

.returnDiv {
  display: flex;
  justify-content: space-between;
  /* This will separate the two buttons */
  align-items: center;
  /* Optional: To vertically align the buttons if they have different heights */
  width: 100%;
  /* Optional: If you want the div to take full width of its parent */
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(72, 194, 48);
}

/* .projectContentTitle {
  color: rgb(72, 194, 48);
} */


.skillsList {
  columns: 2;
  /* Split the list into two columns */
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  /* Remove default bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center the skill cards */
}

.skillsList li {
  background-color: rgba(72, 194, 48, 0.5);
  /* Light background for the card */
  padding: 8px 12px;
  margin: 5px;
  border-radius: 4px;
  /* Rounded corners for the card */
  border: 1px solid rgba(72, 194, 48, 0.1);
  /* Thin border for the card */
  transition: background-color 0.3s ease;
  /* Smooth transition effect for hover state */
}

.skillsList li:hover {
  background-color: rgba(72, 194, 48, 0.2);
  /* Darker background for hover state */
}

.technologiesList {
  list-style-type: none;
  /* Remove default bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  /* Center the skill cards */
}

.technologiesList li {
  background-color: rgba(72, 194, 48, 0.5);
  width: fit-content;
  height: fit-content;
  /* Light background for the card */
  padding: 3px;
  margin: 2px;
  font-size: small;
  border-radius: 4px;
  /* Rounded corners for the card */
  border: 1px solid rgba(72, 194, 48, 0.1);
  /* Thin border for the card */
  transition: background-color 0.3s ease;
  /* Smooth transition effect for hover state */
}


/* New styles for mobile */
@media screen and (max-width: 950px) {

  .projects {
    margin: 0 1rem;
  }


  .projectComponentDetailsContent {
    width: 95%;
    max-width: 90%;
    padding: 10px;
  }

  .projectItem {
    align-items: center;
  }

  .projectComponentDetailsContent h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .projectComponentDetailsContent p {
    font-size: 14px;
  }



  .closeButton {
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    font-size: 16px;
  }

}