/* Navbar.css */
.navbar {
  background-color: #000000;
  /* Blue color for the navigation bar */
  color: #fff;
  padding: 1rem 0;
  margin: 0 auto;
  font-weight: bold;
  transition: 0.5s ease;
  text-align: center;
}

.navbar-list {
  list-style: none;

  max-width: 75%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Allow links to wrap to the next line if needed */
}

.navbar-item {
  flex-grow: 1;
  /* Allow each link to grow and take available space */
  text-align: center;
  /* Center-align the link text */
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  display: block;
  /* Make the entire link clickable */
  padding: 1rem 2rem;
  /* Use rem units for padding */
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  position: relative;
}

.navbar-link::after {
  content: '';
  /* Empty content to create the underline */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Set the width to 100% to make it as long as the text */
  height: 2px;
  /* Height of the underline */
  background-color: rgb(72, 194, 48);
  /* Color of the underline */
  transform: scaleX(0);
  /* Start with no width (scaleX(0)) */
  transform-origin: left;
  /* Set the transform origin to the left */
  transition: transform 0.3s ease;
  /* Animation for the width change */
}

/* Apply styles to the text color on hover */
.navbar-link:hover {
  color: rgb(72, 194, 48);
}

/* Apply styles to the underline width on hover */
.navbar-link:hover::after {
  transform: scaleX(1);
  /* When hovered, grow the underline from left to right (scaleX(1)) */
}

.navbar-link.active {
  color: rgb(72, 194, 48);
}

.navbar-link.active::after {
  transform: scaleX(1);
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: .5s;
  margin-left: 20px;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(72, 194, 48);
  border-radius: 4px;
}

#bar2 {
  transition-duration: .8s;
}

#bar1,
#bar3 {
  width: 70%;
}

#checkbox:checked+.toggle .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkbox:checked+.toggle #bar2 {
  transform: scaleX(0);
  transition-duration: .5s;
}

#checkbox:checked+.toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: .5s;
}

#checkbox:checked+.toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: .5s;
}

#checkbox:checked+.toggle {
  transition-duration: .5s;
  transform: rotate(180deg);
}


.navbar-toggle-container {
  display: flex;
  align-items: center;

}

.displayed-link {
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
  transition: 0.5s ease;
}



/* Media query for responsive design */
@media (max-width: 768px) {
  .navbar-list {
    flex-direction: column;
    align-items: center;
  }

  .navbar-item {
    flex-grow: unset;
    /* Reset flex-grow to its default value */
    margin-bottom: 1rem;
    /* Reset the margin for responsiveness */
  }
}