.contact {
  /* Add any styles for the section here */
  color: white;

  align-items: center;
}


.contactDiv {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: 60%;
  margin-top: 2rem;
  border-radius: 2%;
  background-color: rgb(0, 0, 0);
}


.contactHeaderDiv {
  margin-bottom: 20px;
}

.contactHeaderDiv h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  color: rgb(255, 255, 255);
}

.contactHeaderDiv h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 20%;
  height: 2px;
  background: rgb(72, 194, 48);
  bottom: 0;
  left: 0;
}

.headerDiv {
  padding-left: 10px;
  padding-right: 10px;
}

.text-center{
  text-align: center;
}

.captcha {
  display: inline-block;
}



.contactDetailsDiv {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-gap: 20px;
  max-width: 80%;
  margin-bottom: 20px;
}

.detailsDiv {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: 0 2px 5px 0 rgba(163, 250, 112, 0.15);
  padding: 20px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  border-bottom: 2px rgb(72, 194, 48) solid;
}

.placeText {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
}

.placeExtraText {
  font-size: x-small;
}

.emailTo {
  text-decoration: none;
  color:#fff;
}

.detailsDiv:hover {
  box-shadow: 0 10px 15px 0 rgb(72, 194, 48);
  
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icons a {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
  font-size: xx-large
}

.social-icons a:hover {
  color: #4CAF50;
}

.SocialIcon {
  transition-property: outline-offset, outline-color, background-color;
  transition-duration: 0.25s;
}

.SocialIcon:hover {
  outline-offset: 10px;
  outline: 2px solid #4CAF50;
  border-radius: 50%;
}

.SocialIcon {
  margin: auto;
}



.contactFormDiv form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.form input {
  background-color: transparent;
  border: 0;
  border: none;
  border-bottom: 2px #fff solid;
  display: block;
  font-size: 18px;
  color: #fff;
}

.inputBtn {
  font-size: 12pt;
	color: white;
	background: transparent;
	display: inline-block;
	text-decoration: none;
	padding: 10px 20px;
	border: 3px solid white;
	margin: 20px;
	position: relative;
	letter-spacing: 1px;
	outline: none;
  transition: background-color 0.3s ease;
}


.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid black; /* Give the top border a color */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}


.contactFormDiv form input[name="email"],
.contactFormDiv form textarea {
  grid-column: span 2;
  background-color: transparent;
  border: 0;
  border: none;
  border-bottom: 2px #fff solid;
  display: block;
  font-size: 18px;
  color: #fff;

}

.contactFormDiv form input,
.contactFormDiv form textarea {
  margin-bottom: 10px;
  padding: 15px;
  font-size: 18px;
  outline: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.contactFormDiv form input:hover,
.contactFormDiv form textarea:hover {
  border-color: rgb(72, 194, 48);
  ;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.contactFormDiv form input:focus,
.contactFormDiv form textarea:focus {
  border-color: rgb(72, 194, 48);
  ;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.contactFormDiv form input[type="submit"] {
  cursor: pointer;
  background-color: rgb(72, 194, 48);
  ;
  color: white;
  border: none;
  padding: 15px;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out;
  grid-column: span 2;
  border-radius: 18px;
 -moz-border-radius: 5px;
 -webkit-border-radius: 5px
}

.contactFormDiv form input[type="submit"]:hover {
  background-color: rgb(9, 45, 1);
  color:rgb(76, 185, 52)
  ;
}

.error {
  color: red;
  margin-bottom: 10px;
  font-size: 16px;
  grid-column: span 2;
}

textarea {
  resize: vertical;
}

/* When the screen size is 768px or less, switch to a single column layout */
@media screen and (max-width: 1000px) {
  
  .contactDiv {
    width: 80%;
  }

  .contactDetailsDiv {
    grid-template-columns: repeat(1, 1fr);
    max-width: 100%;
  }

  .contactFormDiv form {
    grid-template-columns: repeat(1, 1fr);
  }

  .contactFormDiv form input[name="email"],
  .contactFormDiv form textarea,
  .contactFormDiv form input[type="submit"],
  .error {
    grid-column: span 1;
  }

  .contactFormDiv form input {
  }
}

