/* Home.css */
.home {
  max-width: 100%;
  margin: 0 auto;
  color: white;
}

.picture {
  position: relative;
  height: 100vh; /* Set the height to cover the full screen */
}

.picture img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scale the image to cover the entire container */
  z-index: 0;
}

.picture::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.PictureText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.PictureText h1 {
  font-family: 'Courier New', monospace;
  font-size: 3rem;
}

.PictureText h3 {
  font-family: 'Courier New', monospace;
  font-size: 1.2rem;
}

.subtitle{
  color: rgb(255, 255, 255);
}


