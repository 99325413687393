@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.Blinking .cursor {
  position: absolute;
  animation: blink 1s step-end infinite !important;
}